import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { locale } from '../../../src/common/i18n'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import route from '../../../src/common/utils/route'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}
// const urls: { id: number; idVideo: string }[] = [
//   {
//     id: 1,
//     idVideo: 'eSgCdE5qyjg',
//   },
//   {
//     id: 2,
//     idVideo: 'RuHT1ZYqESg',
//   },
//   {
//     id: 3,
//     idVideo: '33rLI_SEvD4',
//   },
//   {
//     id: 4,
//     idVideo: 'x0H0Msey0dE',
//   },
//   {
//     id: 5,
//     idVideo: 'w2atu-WqXJE',
//   },
// ]
const LandingFurgonetaCa = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <Title text="Pneumàtics per Furgoneta" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          En el cas dels vehicles que realitzen tasques logístiques l'elecció
          d'uns bons pneumàtics és un factor determinant. Aquest és el cas també
          de les furgonetes i els seus pneumàtics. Disposar d'uns pneumàtics
          adequats per a aquesta classe de vehicles incidirà directament en la{' '}
          <strong>qualitat de conducció</strong> del transportista i en el{' '}
          <strong>consum del vehicle.</strong>
        </p>
        <p className={styles.main_description}>
          Les furgonetes estan pensades per portar grans càrregues de pes. Això
          provoca que els pneumàtics que porten estiguin especialment{' '}
          <strong>preparats per a suportar grans càrregues</strong> en comptes
          de velocitats altes i pesos baixos com succeeix als turismes. Per
          aquest motiu, si equipem una furgoneta amb pneumàtics que no estan
          preparats per aquesta classe de vehicles obtindrem un rendiment molt
          menor del pneumàtic i de la furgoneta en general.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_home'}
        />
        <section className={styles.section}>
          <h2>
            Què diferencia els pneumàtics de furgoneta d'altres pneumàtics?
          </h2>
          <p>
            La principal característica diferenciadora dels pneumàtics de
            turisme dels de furgoneta es troba en què els de furgoneta disposen
            d'un <strong>índex de càrrega superior</strong>. Aquest índex es
            troba en el cas de la furgoneta situat entre{' '}
            <strong>els 100 i els 115 quilos </strong> per cada pneumàtic,
            mentre que en un cotxe se situa entre els 80 i els 100 quilos. Una
            altra diferència fonamental es troba en el{' '}
            <strong>codi de velocitat</strong> del pneumàtic. Les furgonetes
            tenen un ús urbà molt elevat on la velocitat sol ser reduïda. Per
            aquest motiu, els seus pneumàtics estan preparats per aconseguir
            velocitats màximes d'entre 175 km/h i 185 km/h.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>Què vol dir que els pneumàtics de furgoneta estan reforçats?</h2>
          <p>
            Les furgonetes de repartiment com realitzen funcions logístiques i
            ser usades per professionals passen moltes hores realitzant petits
            trajectes de forma contínua. Els seus pneumàtics no només han de
            suportar grans càrregues, sinó que a més han d'estar preparats per a
            lidiar amb el dia a dia de la ciutat. L'ús rutinari del pneumàtic de
            furgoneta fa que sigui procliu a rebre cops contra vorades. Això,
            unit a l'elevat pes de càrrega, provoca un desgast continu de la
            roda. Conscients d'aquesta situació les principals marques de
            pneumàtics han fabricat models concrets per a furgonetes que siguin
            resistents i evitin estrips, punxades, trencaments o rebentades.
          </p>
          <p>
            Els principals fabricants de pneumàtics han elaborat les seves
            solucions per a furgonetes. És el cas, per exemple, dels{' '}
            <strong>Michelin Agilis CrossClimate,</strong> els{' '}
            <strong>Pirelli Carrier</strong> o els{' '}
            <strong>Goodyear EfficientGrip.</strong>
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingFurgonetaEs = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <Title text="Neumáticos para Furgoneta" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          En el caso de los vehículos que realizan tareas logísticas la elección
          de unos buenos neumáticos es un factor determinante. Este es el caso
          también de las furgonetas y sus neumáticos. Disponer de unos
          neumáticos adecuados para esta clase de vehículos incidirá
          directamente en la <strong>calidad de conducción</strong> del
          transportista y en el <strong>consumo del vehículo.</strong>
        </p>
        <p className={styles.main_description}>
          Las furgonetas están pensadas para llevar grandes cargas de peso. Esto
          provoca que los neumáticos que llevan estén especialmente preparados
          para soportar grandes cargas en vez de velocidades altas y pesos bajos
          como sucede en los turismos. Por este motivo, si equipamos una
          furgoneta con neumáticos que no están preparados para ellas
          obtendremos un rendimiento mucho menor del neumático y de la furgoneta
          en general.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_home'}
        />
        <section className={styles.section}>
          <h2>
            ¿Qué diferencia a los neumáticos de furgoneta de otros neumáticos?
          </h2>
          <p>
            La principal característica diferenciadora de los neumáticos de
            turismo de los de furgoneta se encuentra en que los neumáticos de
            furgoneta disponen de un índice de carga superior. Este índice se
            encuentra en el caso de la furgoneta situado entre los{' '}
            <strong>100 y los 115 kilos</strong> por cada neumático, mientras
            que en un coche se sitúa entre los 80 y los 100 kilos.
            <br />
            Otra diferencia fundamental se encuentra en el{' '}
            <strong>código de velocidad</strong> del neumático. Las furgonetas
            tienen un uso urbano muy elevado donde la velocidad suele ser
            reducida. Por este motivo, sus neumáticos están preparados para
            alcanzar velocidades máximas de entre 175 Km/h y 185 Km/h.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            ¿Qué significa que los neumáticos de furgoneta están reforzados?
          </h2>
          <p>
            Las furgonetas de reparto al realizar funciones logísticas y ser
            usadas por profesionales pasan muchas horas realizando pequeños
            trayectos de forma continua. Sus neumáticos no solo deben soportar
            grandes cargas, sino que además deben estar preparados para lidiar
            con el día a día de la ciudad. El uso rutinario del neumático de
            furgoneta hace que sea proclive a recibir golpes contra bordillos.
            Esto, unido al elevado peso de la carga, provoca un desgaste
            continuo de la rueda. Conscientes de esta situación las principales
            marcas de neumáticos han fabricado modelos concretos para furgonetas
            que sean{' '}
            <strong>
              resistentes y eviten desgarros, pinchazos, roturas o reventones
            </strong>
            .<br />
            Los principales fabricantes de neumáticos han elaborado sus
            soluciones para furgonetas. Es el caso, por ejemplo, de los{' '}
            <strong>Michelin Agilis CrossClimate,</strong> els{' '}
            <strong>Pirelli Carrier</strong> o els{' '}
            <strong>Goodyear EfficientGrip.</strong>
          </p>
        </section>
      </article>
    </div>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-furgoneta'),
        temporada: {
          selectable: process.env.GATSBY_WEB === 'rodi',
          value: 'verano',
        },
        tipoVehiculo: 1,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'furgoneta',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer
          showMarcas={false}
          origin={route('landing-furgoneta')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={process.env.GATSBY_WEB === 'rodi'}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const LandingFurgoneta = ({ ...props }: Props) =>
  locale === 'es' ? (
    <LandingFurgonetaEs {...props} />
  ) : (
    <LandingFurgonetaCa {...props} />
  )

export default LandingFurgoneta
